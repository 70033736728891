import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  PoolsState,
  ProductPool,
  ProviderPool,
  TaxonomyItemPool,
} from './types'

const initialState: PoolsState = {
  providerPools: [],
  providerPoolsLoading: true,
  taxonomyItemPools: [],
  taxonomyItemPoolsLoading: true,
  productPools: [],
  productPoolsLoading: true,
}

const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
    setProviderPools(state, action: PayloadAction<ProviderPool[]>) {
      state.providerPools = action.payload
      state.providerPoolsLoading = false
    },
    setProviderPoolsLoading(state, action: PayloadAction<boolean>) {
      state.providerPoolsLoading = action.payload
    },
    setTaxonomyItemPools(state, action: PayloadAction<TaxonomyItemPool[]>) {
      state.taxonomyItemPools = action.payload
      state.taxonomyItemPoolsLoading = false
    },
    setTaxonomyItemPoolsLoading(state, action: PayloadAction<boolean>) {
      state.taxonomyItemPoolsLoading = action.payload
    },
    setProductPools(state, action: PayloadAction<ProductPool[]>) {
      state.productPools = action.payload
      state.productPoolsLoading = false
    },
    setProductPoolsLoading(state, action: PayloadAction<boolean>) {
      state.productPoolsLoading = action.payload
    },
  },
})

export default poolsSlice.reducer

export const {
  setProviderPools,
  setProviderPoolsLoading,
  setTaxonomyItemPools,
  setTaxonomyItemPoolsLoading,
  setProductPools,
  setProductPoolsLoading,
} = poolsSlice.actions
